
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { IProduct } from "@/types/product";

@Component({})
export default class extends Vue {
  @Getter("product/single")
  public product!: IProduct;

  // fields
  public product_id = 0;

  public created() {
    this.product_id = Number(this.$route.params.product_id);
  }
}
